import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import logo from './../img/logo.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: 'ease-out',
    });
  }, []);
  const navigator = useNavigate();
  return (
    <div>
      <nav 
        className="bg-nav rounded-xl p-1 pr-10 pl-10 m-5 lg:ml-20 lg:mr-20 flex flex-col md:flex-row justify-between items-center"
        data-aos="fade-down"
      >
        <div className="flex items-center justify-between m-5 w-full md:w-auto">
          <img 
            src={logo} 
            alt="Logo" 
            className="lg:h-20 h-12"
            data-aos="fade-right"
            onClick={() =>navigator('/')}
          />
          <div className="md:hidden">
            <button 
              onClick={() => setIsOpen(!isOpen)} 
              className="text-text focus:outline-none"
              data-aos="fade-left"
            >
              <FaBars className="h-6 w-6" />
            </button>
          </div>
        </div>
        <div 
          className={`flex-col md:flex md:flex-row items-center justify-center w-full md:w-auto ${isOpen ? 'flex' : 'hidden'}`}
        >
          <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-14 text-text mt-4 md:mt-0">
            <li><a href="/#home" className="hover:text-primary">Home</a></li>
            <li><a href="/#blogs" className="hover:text-primary">Blogs</a></li>
            <li><a href="/course" className="hover:text-primary">Courses</a></li>
            <li><a href="/b2b" className="hover:text-primary">B2B</a></li>
            <li><a href="/#about-us" className="hover:text-primary">About us</a></li>
          </ul>
          <Link 
            to="/contact" 
            className="bg-primary text-white py-2 px-4 rounded-full hover:bg-accent mt-4 md:mt-0 md:ml-4"
            data-aos="fade-left"
          >
            Get Started
          </Link>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
